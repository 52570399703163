.section-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 40px 0px;
}
.container-news {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 20px;
  grid-row-gap: 34px;
  justify-items: stretch;
  align-items: center;
  max-width: 1150px;
}
.container-card-news {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  border-radius: 15px;
}
.card-link-news-row {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 280px;
}

.container-card-news:hover,
.container-card-news:focus {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 20px rgba(0, 0, 0, 0.22);
  color: #444;
}
.category-nick {
  padding-left: 10px;
}
.card-img {
  padding: 10px;
  border-radius: 15px;
  height: 200px; /* height of container */
  object-fit: cover;
}
.card-txt {
  padding: 10px;
  text-align: justify;
}
.card-txt2 {
  padding: 10px;
  text-align: justify;
}
.icon-views {
  width: 13px;
}

.card-views {
  display: flex;
  justify-content: flex-end;
  text-align: center;
}
div.card-views {
  padding-right: 10px;
  margin: 0px;
  align-items: center;
}
.txt-views p {
  margin: 2px;
  margin-right: 5px;
  font-size: clamp(0.2em, 1em + 1vw, 0.7em);
}
.txt-views {
  padding-right: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.card-txt h6 {
  font-size: clamp(0.5em, 1em + 1vw, 0.86em);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  text-align: justify;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.news-body {
  margin: 10px;
  font-size: clamp(0.2em, 1em + 1vw, 0.7em);
  color: rgb(82, 81, 81);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  text-align: justify;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.news-body2 {
  font-size: clamp(0.2em, 1em + 1vw, 0.7em);
  color: rgb(82, 81, 81);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  text-align: justify;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.card-txt2 h6 {
  font-size: clamp(0.5em, 1em + 1vw, 0.86em);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  text-align: justify;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.card-news-all-txt.card-txt2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.link-news-card,
.card-txt,
.card-views-top,
.card-views {
  text-decoration: none;
  color: #000000;
}
.link-news-card:hover {
  text-decoration: none;
  color: #030303;
}
.news-body:hover {
  color: #747474;
}
.card-txt:hover {
  color: #919191;
}
@media only screen and (max-width: 1210px) {
  .container-news {
    grid-template-columns: 280px 280px;
    grid-row-gap: 20px;
    justify-content: center;
  }
}
@media only screen and (max-width: 730px) {
  .container-news {
    grid-template-columns: 200px 200px;
    justify-content: center;
  }
}
@media screen and (max-width: 460px) {
  .card-link-news-row {
    max-width: 340px;
  }
  .container-news {
    grid-template-columns: 340px;
    justify-content: center;
    margin-bottom: 30px;
  }
  .section-title {
    margin-top: 40px;
    max-width: 1350px;
    height: 13%;
  }
  .section-news {
    margin-top: 10px;
    height: 33%;
  }
  .cards-news {
    width: 44%;
  }
  .news-body {
    font-size: clamp(0.5em, 1em + 1vw, 0.7em);
  }

  .icon-views {
    width: 8px;
  }
}
